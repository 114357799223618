import React, { FC, useState } from 'react';
import { validateEmail } from '../../utils/dataValidation';

type TEmailBoxProps = {
  onEmailSubmit: (email: string) => void;
  isEmailSubmitted: boolean;
  handleClickPrivacy: () => void;
};
const EmailBox: FC<TEmailBoxProps> = ({ onEmailSubmit, isEmailSubmitted, handleClickPrivacy }) => {
  const [email, setEmail] = useState<string>('');
  const [isChecked, setIsChecked] = useState<any>('');
  const [error, setError] = useState<string>('');

  const handelEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const handleSubmitEmail = () => {
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    onEmailSubmit(email);
  };
  const isButtonDisabled = !isChecked || !email;
  return isEmailSubmitted ? (
    <SuccessMessage />
  ) : (
    <div className="flex-column email-container">
      <p className="email-text">
        To see the full results please provide your email address.
      </p>
      <div className="flex-column">
        <input
          className="input"
          name="privacy-policy"
          placeholder="Your email..."
          value={email}
          onChange={handelEmailChange}
          type="email"
        />
        <p className="error-text">{error}</p>
      </div>
      {' '}
      <div className="flex-row checkbox-container">
        <input
          className="checkbox"
          type="checkbox"
          name="privacy-policy"
          value={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
        />
        <label>
          I agree to the <button className="button-intext" onClick={handleClickPrivacy} style={{fontSize: '1.5rem'}}>
            privacy policy
          </button>
        </label>
      </div>
      <div className="flex-center">
        <button
          className="btn email-btn"
          onClick={handleSubmitEmail}
          disabled={isButtonDisabled}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

const SuccessMessage: FC = () => (
  <div className="flex-center email-success-container">
    <p className="email-success-text">Thank you.</p>
  </div>
);

export default EmailBox;
