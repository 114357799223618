import './ProgressBar.css';

interface Props {
  bgColor: string;
  progress: number;
  low: number;
  high: number;
}

const ProgressBar = ({ bgColor, progress, low = 0, high = 100 }: Props) => {
  const progressPercent = ((progress - low) / (high - low)) * 100;
  const fillerStyle = {
    width: `${progressPercent}%`,
    backgroundColor: bgColor,
  };

  return (
    <div className="progressbar-container">
      <div className="progressbar-filler" style={fillerStyle}>
        <span className="progressbar-label"></span>
      </div>
    </div>
  );
};

export default ProgressBar