import React, { FC } from 'react';
import { Category, CategoryScore } from '../../models';
import { ScoreBar } from '../ScoreBar';
import './Result.css';
import ga from '../../utils/googleAnalytics';
import EmailBox from './EmailBox';
import zapier from '../../utils/zapierIntegration';

interface ResultProps {
  result: Map<Category, CategoryScore>;
  reset: () => void;
  isEmailSubmitted: boolean;
  setIsEmailSubmitted: (value: boolean) => void;
  handleClickPrivacy: () => void;
}

export const Result: FC<ResultProps> = ({ result, reset, isEmailSubmitted, setIsEmailSubmitted, handleClickPrivacy }) => {

  const handleClickContactUs = () => {
    const url = process.env.REACT_APP_CONTACT_US_LINK;
    ga.events.contactUsButtonClicked();
    window.open(url, '_blank');
  };

  const handleEmailSubmit = (email: string) => {
    zapier.storeEmail(email);
    setIsEmailSubmitted(true);
  };

  const findWeakestCatScore = () => {
    const catScores = Array.from(result.values())
    if (catScores.length > 0)
      return catScores.reduce((minItem, currentItem) => {
        return currentItem.score < minItem.score ? currentItem : minItem;
      })
    else
      return {title: "n/a"}
  }

  return (
    <div className="results-container justify-center flex-center flex-column">
      <p className="result-text">Here’s how you score compared to hundreds of companies assessed by TechMiners.
        Looks like you should focus on {findWeakestCatScore().title} most. <button className="button-intext" onClick={handleClickContactUs}>Let’s talk</button> and find out how!
      </p>

      {Array.from(result.entries()).map(([category, categoryScore], idx) => {
        const isBlurred = idx >= 1 && !isEmailSubmitted;
        return (
          <>
            <div key={category} className="score-container">
              <div className="score-text-container">
                <h3>
                  {category}:
                </h3>
                <h3
                  style={{
                    color: isBlurred ? 'transparent' : categoryScore.color,
                    marginLeft: '10px',
                    textShadow: isBlurred ? '0 0 8px #000' : 'none',
                  }}
                >
                  {isNaN(categoryScore.score) ? 'n/a' : categoryScore.score}
                </h3>
              </div>

              <div className="score-bar-container">
                <ScoreBar
                  height={20}
                  bars={categoryScore.bars}
                  minVal={0}
                  maxVal={100}
                  indicators={categoryScore.indicators}
                  isBlurred={isBlurred}
                />
              </div>
            </div>
            {(idx === 0 && !isEmailSubmitted) && (<EmailBox
              onEmailSubmit={handleEmailSubmit}
              isEmailSubmitted={isEmailSubmitted}
              handleClickPrivacy={handleClickPrivacy}
            />)}
          </>
        );
      })}
      <button className="btn" onClick={handleClickContactUs}>
        Schedule a call with one of our CTOs
      </button>
      <button
        style={{
          border: 'none',
          backgroundColor: 'transparent',
          fontSize: '1.6rem',
          fontWeight: 700,
          cursor: 'pointer',
          textDecoration: 'underline',
          marginTop: '1rem',
        }}
        onClick={reset}
      >
        Retake the assessment
      </button>
    </div>
  );
};
