import React, { FC, useEffect, useState } from 'react';
import Question from '../Question';
import { Category, CategoryScore, IndicatorProps, Option } from '../../models';
import { benchmarks, questions } from '../../data/Data';
import ga from '../../utils/googleAnalytics';
import zapier from '../../utils/zapierIntegration';
import Result from '../Result';
import ProgressBar from '../ProgressBar';

export interface ScoreResult {
  score: number;
  factor: number;
}

type TSQuestionnaireProps = {
  handleClickPrivacy: () => void
};

export const Questionnaire: FC<TSQuestionnaireProps> = ({handleClickPrivacy}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState<boolean>(false);
  const [result, setResult] = useState<Map<Category, CategoryScore>>(
    new Map<Category, CategoryScore>(),
  );

  const handleAnswer = (answer: Option) => {
    ga.events.questionAnswered(currentIndex, answer.score);
    const newAnswers = [...selectedOptions];
    newAnswers[currentIndex] = answer;
    setSelectedOptions(newAnswers);
    setCurrentIndex(currentIndex + 1);
  };

  const handleGoBack = () => {
    currentIndex > 0 && setCurrentIndex(currentIndex - 1);
  };

  const reset = () => {
    setCurrentIndex(0);
    setSelectedOptions([]);
    setResult(new Map<Category, CategoryScore>());
  };

  const stepColors = {
    low: '#ff8469',
    medium: '#7697ec',
    high: '#a9dc77',
  };

  useEffect(() => {
    const scoreResultsByCategory = new Map<Category, number[]>();
    if (currentIndex === questions.length) {
      // Group responses by category
      selectedOptions.forEach((selectedOption, index) => {
        const question = questions[index];
        //const { category, reference } = question;
        const { category } = question;
        // Removed for the time being: reference reflects our expectation values, which we can't set here.
        //const score = selectedOption.score - reference;
        const score = selectedOption.score;
        const currentCategoryScore = scoreResultsByCategory.get(category);
        if (currentCategoryScore) {
          scoreResultsByCategory.set(category, [
            ...currentCategoryScore,
            score,
          ]);
        } else {
          scoreResultsByCategory.set(category, [score]);
        }
      });

      // Calculate score for each category
      const scoreBars = new Map<Category, CategoryScore>();
      scoreResultsByCategory.forEach((scoreResult, category) => {
        //const zeroScores = scoreResult.filter((score) => score === 0);
        const positiveScores = scoreResult.filter((score) => score > 0);
        //const finalScore = Math.round(
        // (0.75 * (zeroScores.length / scoreResult.length) +
        // (positiveScores.length / scoreResult.length) *
        // 100,
        //);
        const finalScore = Math.round(
          (positiveScores.reduce((total, item) => total + item, 0) /
            (positiveScores.length * 40)) *
          100,
        );

        const benchmark = benchmarks.find(
          (benchmark) => benchmark.category === category,
        )!;
        const bars = [
          { width: benchmark.low, color: stepColors.low },
          { width: benchmark.high - benchmark.low, color: stepColors.medium },
          { width: 100 - benchmark.high, color: stepColors.high },
        ];

        const indicators: IndicatorProps[] = [
          {
            value: benchmark.low,
            title: 'low',
            color: stepColors.low,
            position: 'top',
          },
          { value: finalScore, title: 'you', color: 'black', position: 'top' },
          {
            value: benchmark.high,
            title: 'high',
            color: stepColors.high,
            position: 'top',
          },
        ];
        scoreBars.set(category, {
          score: finalScore,
          color:
            finalScore < benchmark.low
              ? stepColors.low
              : finalScore > benchmark.high
                ? stepColors.high
                : stepColors.medium,
          title: category,
          bars,
          indicators,
        });
      });

      zapier.storeResult({ scoreBars, answers: selectedOptions });
      setResult(scoreBars);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <>
      {currentIndex < questions.length ? (
        <>
          <h1 className="text-center">Tech & Team Assessment</h1>
          {' '}
          <ProgressBar
            bgColor={'#3b6ae3'}
            progress={currentIndex}
            low={0}
            high={questions.length}
          />
          <Question
            index={currentIndex}
            text={
              currentIndex +
              1 +
              '/' +
              questions.length +
              ' - ' +
              questions[currentIndex].text
            }
            options={questions[currentIndex].options}
            onAnswer={handleAnswer}
            goBack={handleGoBack}
          />
        </>
      ) : (
        <>
          <h1 className="text-center">Benchmark Results</h1>
          <Result result={result} reset={reset} isEmailSubmitted={isEmailSubmitted}
                  setIsEmailSubmitted={setIsEmailSubmitted} handleClickPrivacy={handleClickPrivacy} />
        </>
      )
      }
    </>
  );
};
