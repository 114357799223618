import { Option } from '../../models';
import './Question.css';

interface Props {
  index: number;
  text: string;
  options: Option[];
  onAnswer: (answer: Option) => void;
  goBack: () => void;
}

const Question = ({ index, text, options, onAnswer, goBack }: Props) => {
  return (
    <div key={index} className="fade">
      <h3 className="question-title">{text}</h3>
      {options.map((option) => (
        <div key={option.text} className="option-container">
          <p className="option-text" onClick={() => onAnswer(option)}>
            {option.text}
          </p>
        </div>
      ))}
      {index > 0 && (
        <button className="back-button" onClick={goBack}>{`Back`}</button>
      )}
    </div>
  );
};

export default Question;
