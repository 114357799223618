import React, { FC } from 'react';
import './StartPage.css';

type TStepPageProps = {
  handleNext: () => void
  handleClickPrivacy: () => void
};

/*
const Page1: FC<TStepPageProps> = ({ handleNext }) => {
  return (
    <div className="flex-column">
      <div className="teaser-container">
        <h1 className="teaser-title">Yes, your plan is sound. But is your tech ready?</h1>

        <p className="teaser-text">Whatever your strategy, be it a funding round, steep business growth or a general
          change of gears: Your tech & team needs to be in shape to support, not impede.</p>

        <p className="teaser-strong">TechMiners gets you ready for the next stage:</p>

        <ul className="teaser-bullets">
          <li>Readiness for funding or exit</li>
          <li>Fitness for growth or downscaling</li>
          <li>Capacity for innovation</li>
        </ul>

        <p className="teaser-cta-text">Find out where your tech & team stands in under 10 minutes.</p>
      </div>

      <button className="btn teaser-btn" onClick={handleNext}>
        Benchmark your tech now
      </button>
    </div>
  );
};

const Page2: FC<TStepPageProps> = ({ handleNext }) => {
  return (
    <div className="flex-column">
      <div className="teaser-container">
        <h1 className="teaser-title">Before you start</h1>

        <p className="teaser-text">After hundreds of tech assessments we know all common tech pain points and how to
          identify them quickly. We will ask a few questions to figure this out, the result is a benchmarked scoring in
          the areas of Tech, Team, Product and Scalability.

        </p>

        <ul className="teaser-tips">
          <li><strong>Privacy:</strong> Your data is safe with us and will not be shared with anyone but you</li>
          <li><strong>Honesty:</strong> Try to be as brutally honest as you can be, the accuracy of the scoring depends
            on it
          </li>
          <li><strong>Expectation:</strong> The benchmarked scoring result will help you decide what to do next</li>
          <li><strong>Disclaimer:</strong> This tool is meant to provide a first impression, not to replace a detailed
            assessment
          </li>
        </ul>
      </div>

      <button className="btn teaser-btn" onClick={handleNext}>
        Start
      </button>
    </div>
  );
};
 */

const Page3: FC<TStepPageProps> = ({ handleNext, handleClickPrivacy }) => {
  return (
    <div className="flex-column">
      <div className="teaser-container">
        <h1 className="teaser-title">Is your tech organization ready for the next level?</h1>

        <div className="centered">
          <ul className="teaser-bullets3">
            <li>Planning to raise a <strong>funding round</strong>?</li>
            <li>Getting ready for an <strong>exit</strong>?</li>
            <li><strong>Growing</strong> your tech team?</li>
            <li>Looking for ways to <strong>boost productivity</strong>?</li>
            <li>Unsure about your <strong>IT security?</strong></li>
          </ul>
        </div>

        <p className="teaser-strong centered">Find out how you stack up against others</p>
        <p className="teaser-text centered">in less than 10 minutes</p>
      </div>

      <button className="btn teaser-btn" onClick={handleNext}>
        Benchmark your tech <br />& product organisation
      </button>

      <div className="teaser-container" style={{ marginTop: '3rem' }}>
        <p className="teaser-text centered">Get your <strong>free benchmark result</strong> against our database of
          hundreds of ventures <strong>immediately</strong> after the assessment.</p>
        <p className="teaser-text centered">No data is shared with anybody except you (
          <button className="button-intext" onClick={handleClickPrivacy}>
            privacy policy
          </button>).
        </p>
      </div>

    </div>
  );
};


type TStartPageProps = {
  handleStart: () => void
  handleClickPrivacy: () => void
};
const StartPage: FC<TStartPageProps> = ({ handleStart, handleClickPrivacy }) => {
  return (
    <Page3 handleClickPrivacy={handleClickPrivacy} handleNext={handleStart} />
  );
};

export default StartPage;
