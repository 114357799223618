import { Benchmark, Question } from '../models';

export const questions: Question[] = [
  {
    category: 'Tech',
    text: 'How would you evaluate the selection of technologies used in the development of your products?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: 'Outdated or incompatible technology significantly hampers development and poses challenges in recruitment',
      },
      {
        score: 20,
        text: 'There are improved alternatives available for parts of our technology stack, which could alleviate some issues',
      },
      {
        score: 30,
        text: 'The tech ecosystem offers substantial support, enabling us to construct our product effectively',
      },
      {
        score: 40,
        text: 'Our technology stack allows us to progress quickly and is appealing to top talent in the field',
      },
    ],
  },
  {
    category: 'Tech',
    text: 'To what extent does the present software architecture cater to your business needs?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: 'A major overhaul is crucial before we can even consider aspects like scalability and extendability',
      },
      {
        score: 20,
        text: 'Certain components need to be redesigned or replaced to prevent scalability bottlenecks',
      },
      {
        score: 30,
        text: 'We anticipate no issues with scaling in the upcoming 12 months',
      },
      {
        score: 40,
        text: 'We are well-equipped to scale for the next three years or more',
      },
    ],
  },
  {
    category: 'Tech',
    text: 'How much developer capacity is spent on fixing bugs or rewriting things rather than building new features?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: 'A significant portion of our engineering capacity is consistently dedicated to reducing technical debt',
      },
      {
        score: 20,
        text: 'The team is capable of advancing the product, however, a lot of time is invested in managing technical debt',
      },
      {
        score: 30,
        text: 'Less than 20% of our engineering time is spent on reducing technical debt',
      },
      {
        score: 40,
        text: 'Less than 10% of our engineering time is allocated to the reduction of technical debt',
      },
    ],
  },
  {
    category: 'Tech',
    text: 'How much have technical issues affected your business in the previous 12 months?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: 'System outages have caused customer churn and revenue loss due to breaches of the Service Level Agreement (SLA)',
      },
      {
        score: 20,
        text: 'Occasional system outages occur that require intervention',
      },
      {
        score: 30,
        text: 'System outages are rare for us; when they do occur, we can almost always recover quickly, typically within an hour',
      },
      {
        score: 40,
        text: 'Our high uptime standard (99.99% or more) has resulted in high customer satisfaction',
      },
    ],
  },
  {
    category: 'Tech',
    text: 'How well does your organisation adhere to IT security and data protection standards?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: "We don't have any dedicated IT security processes or staff members in place yet",
      },
      {
        score: 20,
        text: "We ensure basic GDPR compliance, but efforts don't extend beyond that",
      },
      {
        score: 30,
        text: 'Processes are well-documented and regularly updated, roles are assigned, and staff receive regular training',
      },
      {
        score: 40,
        text: 'We have a dedicated DPO (Data Protection Officer) and IT security staff, and we have a valid certification of our policies and processes, e.g. ISO27001 and SOC2',
      },
    ],
  },
  {
    category: 'Team',
    text: 'Are all the necessary skills available to your tech and product organisation, at the appropriate level of seniority?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: 'Our progress is significantly slowed by a lack of necessary expertise',
      },
      {
        score: 20,
        text: 'While most of the skills are present within the team, additional experts in key areas would enhance productivity',
      },
      {
        score: 30,
        text: 'We have senior staff in place for the most critical roles',
      },
      {
        score: 40,
        text: 'All the necessary knowledge for our product and technology needs is available within our company',
      },
    ],
  },
  {
    category: 'Team',
    text: 'What would be the impact on the company if a key individual were to unexpectedly depart from the organisation?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: 'If a key individual were to leave, it could result in substantial revenue loss, for example, if system maintenance could not be maintained',
      },
      {
        score: 20,
        text: 'While we have some areas with limited knowledge redundancy, we are aware of such gaps and aim to recruit specifically in these areas',
      },
      {
        score: 30,
        text: 'Very few of our IP is handled by single contributors, and we document meticulously to prevent knowledge loss',
      },
      {
        score: 40,
        text: 'All key roles have a designated backup, with transition plans in place',
      },
    ],
  },
  {
    category: 'Team',
    text: 'How reliably does your tech & product team deliver new features?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: 'Estimations are often inaccurate, and deadlines are frequently not met',
      },
      {
        score: 20,
        text: "Although the team consistently delivers new features, estimations and outcomes of development cycles aren't reliably predictable",
      },
      {
        score: 30,
        text: 'The team consistently and reliably introduces new features, and accurate estimations support predictable outcomes',
      },
      {
        score: 40,
        text: 'Our teams manage to exceed required outcomes regularly',
      },
    ],
  },
  {
    category: 'Product',
    text: 'How are end users involved in your product exploration process?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: 'We currently do not involve users in our process, operating on the assumption that we understand their needs',
      },
      {
        score: 20,
        text: "We occasionally solicit feedback, but it's not an inherent part of our discovery process",
      },
      {
        score: 30,
        text: 'Generally, we actively involve users when contemplating significant updates',
      },
      {
        score: 40,
        text: "We don't initiate work on new features without first validating with our users, using methods such as interviews or prototypes",
      },
    ],
  },
  {
    category: 'Product',
    text: 'To what extent is your prioritisation process guiding you to work on the most valuable initiative?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: "Our task choices are typically driven by personal preference or leadership's favourite projects",
      },
      {
        score: 20,
        text: "While we don't adhere to a strict process, we usually stay committed to initiatives once begun, without continuous interruption, for instance from sales staff",
      },
      {
        score: 30,
        text: 'We apply a repeatable method, such as RICE or ICE, to prioritise initiatives',
      },
      {
        score: 40,
        text: 'We utilise reliable methods to determine the potential value of new initiatives, and we regularly reassess to adapt to the changing environment',
      },
    ],
  },
  {
    category: 'Product',
    text: 'How effectively do you use product performance and usage data to guide your product management initiatives?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: "Currently, our product management isn't guided by data",
      },
      {
        score: 20,
        text: 'We gather some fundamental metrics, such as site visits, but our approach is rather basic',
      },
      {
        score: 30,
        text: 'We routinely collect a variety of performance metrics, represent them visually, and conduct regular tests',
      },
      {
        score: 40,
        text: 'Our product management is driven by data insights obtained from A/B testing, prototypes, and comprehensive business KPIs',
      },
    ],
  },
  {
    category: 'Product',
    text: 'How significant is the effort required from your side to onboard new clients (or users)?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: 'Onboarding each client requires considerable effort from us, including code modifications and hands-on assistance',
      },
      {
        score: 20,
        text: 'We frequently need to assist clients with configurations and adjustments before they are set up and ready to proceed',
      },
      {
        score: 30,
        text: 'Most clients manage their own onboarding process, using our documentation with minimal involvement from our sales and customer success teams',
      },
      {
        score: 40,
        text: 'Most of the onboarding process is automated and self-managed, requiring minimal or no involvement from us',
      },
    ],
  },
  {
    category: 'Scalability',
    text: 'How effectively can your technical infrastructure handle a 10x or 100x increase in your user base?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: 'To accommodate scalability, we need to manually install new hardware or implement significant modifications to our existing architecture',
      },
      {
        score: 20,
        text: 'We would need to dedicate some time to manually allocate additional resources',
      },
      {
        score: 30,
        text: 'The majority of our components would automatically adapt to increased demands',
      },
      {
        score: 40,
        text: "We wouldn't need to undertake any action - our infrastructure provisioning and orchestration are fully automated",
      },
    ],
  },
  {
    category: 'Scalability',
    text: 'How would a 10x or 100x increase in users impact cost of your technical infrastructure, including staffing requirements?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: 'The average cost per user is likely to increase at scale',
      },
      { score: 20, text: 'The cost per user would remain stable' },
      {
        score: 30,
        text: 'As we scale, the cost per user will slightly decrease',
      },
      {
        score: 40,
        text: 'When scaling, the cost per user would significantly decrease, becoming less relevant',
      },
    ],
  },
  {
    category: 'Scalability',
    text: 'How would productivity in your tech team change if you were to add new staff or entire teams to your organisation?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: 'If our setup remains as it currently stands, productivity per individual or team would likely decrease, rather than increase',
      },
      {
        score: 20,
        text: 'We could potentially bring a few more individuals into our teams before organisational or tooling changes would be necessary',
      },
      {
        score: 30,
        text: 'Incorporating a few additional teams should not diminish productivity. Our tools and agile organisational structure can accommodate it',
      },
      {
        score: 40,
        text: 'We are well-equipped for scaling and have previously added teams, which have had a measurable positive impact on productivity',
      },
    ],
  },
  {
    category: 'Scalability',
    text: 'After their onboarding, how rapidly do new members of your tech team begin to contribute productively to the team?',
    reference: 30,
    options: [
      { score: 0, text: "I don't know" },
      {
        score: 10,
        text: 'Typically, new team members require several months to be able to independently contribute to the product',
      },
      {
        score: 20,
        text: 'After a few weeks, individuals are prepared to contribute productively',
      },
      {
        score: 30,
        text: 'Our documentation and onboarding processes aim to get new team members up to speed within a few days or weeks',
      },
      {
        score: 40,
        text: 'We foster self-service documentation and onboarding, enabling new team members to become productive within their first week',
      },
    ],
  },
];

export const benchmarks: Benchmark[] = [
  {
    category: 'Tech',
    low: 45,
    high: 63,
  },
  {
    category: 'Team',
    low: 53,
    high: 73,
  },
  {
    category: 'Product',
    low: 48,
    high: 70,
  },
  {
    category: 'Scalability',
    low: 35,
    high: 65,
  },
];

export const startTeaserContent = {
  startText:
    "Curious about the efficiency and performance of your product and tech departments? Let's uncover it together!",
  items: [
    {
      title: '💡 Simplicity',
      description: 'Complete our streamlined questionnaire in under 5 minutes.',
    },
    {
      title: '📊 Personalized Insights',
      description:
        'Receive an exclusive TechMiners score tailored to your business.',
    },
    {
      title: '🏆 Industry Benchmarks',
      description:
        'See how you stack up against top-tier standards derived from hundreds of Technology Due Diligence projects.',
    },
  ],
  endText:
    'Start here to fine-tune your strategies and harness the full potential of your tech investments!',
};
