import React from 'react';
import Style from 'style-it';
import styles from './ScoreBar.module.css';
import { IndicatorProps } from '../../models';

interface Props {
  height?: number;
  bars?: any[];
  minVal?: number;
  maxVal?: number;
  fontSize?: number;
  indicators?: IndicatorProps[];
  isBlurred?: boolean;
}

export const ScoreBar = ({
  height = 10,
  bars = [],
  minVal = 0,
  maxVal = 100,
  fontSize = 15,
  indicators = [],
  isBlurred = false,
}: Props) => {
  let sequenceSum = 0;
  bars.forEach((itm) => {
    sequenceSum += itm.width;
    itm.value = ((maxVal - minVal) * sequenceSum) / 100 + minVal;
  });

  let stepValue_left = 0;

  return (
    <div className={styles.ScoreBar}>
      {isBlurred && <div className={styles.blurred} />}
      <div className={styles.progress} style={{ height: `${height}px` }}>
        {bars.map((itm, idx) => {
          return (
            <div
              key={`progress-${idx}`}
              style={{
                width: `${isBlurred ? 100 / 3 : itm.width}%`,
                background: itm.color,
              }}
            ></div>
          );
        })}
      </div>
      <div
        className={styles.stepValueWrapper}
        style={{ fontSize: `${fontSize}px` }}
      >
        <div className={styles.stepValue}>{minVal}</div>
        {bars.map((itm, idx) => {
          stepValue_left += isBlurred ? 33 : itm.width;
          return (
            <div
              key={`value-${idx}`}
              className={styles.stepValue}
              style={{ left: `${stepValue_left}%` }}
            >
              {isBlurred ? 0 : itm.value}
            </div>
          );
        })}
      </div>
      {!isBlurred &&
        indicators
          .filter(indicator => indicator.title === "you")
          .map((indicator, idx) => {
          let normalized_value = Number(
            ((indicator.value - minVal) / (maxVal - minVal)).toFixed(2),
          );

          // value might be NaN
          if (isNaN(indicator.value)) {
            return <></>
          }

          return indicator.position === 'top'
            ? Style.it(
                `.${styles.indicator} {top: -100% !important;  transform: translateX(-50%) rotate(180deg);} .${styles.indicator}::before {border-bottom-color: ${indicator.color}}`,
                <div
                  key={`indicator-${idx}`}
                  className={styles.indicator}
                  style={{
                    left: `${normalized_value * 100}%`,
                    backgroundColor: indicator.color,
                  }}
                >
                  <p
                    className={styles['indicator-title']}
                    style={{ transform: 'rotate(180deg)' }}
                  >
                    {indicator.title}
                  </p>
                </div>,
              )
            : Style.it(
                `.${styles.indicator} {bottom: -100% !important; transform: translateX(-50%)} .${styles.indicator}::before {border-bottom-color: ${indicator.color}}`,
                <div
                  key={`indicator-${idx}`}
                  className={styles.indicator}
                  style={{
                    left: `${normalized_value * 100}%`,
                    backgroundColor: indicator.color,
                  }}
                >
                  <p
                    className={styles['indicator-title']}
                    style={{ marginTop: '8px' }}
                  >
                    {indicator.title}
                  </p>
                </div>,
              );
        })}
    </div>
  );
};
