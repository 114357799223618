import reactGA from 'react-ga4';

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID ?? '';

const initialize = () => {
  if (!GA_TRACKING_ID) return;
  reactGA.initialize(GA_TRACKING_ID, {
    gaOptions: {
      cookieFlags: 'SameSite=None; Secure',
    },
  });
};

const events = {
  startButtonClicked: () => {
    reactGA.event({ category: 'Survey', action: 'Started' });
  },
  questionAnswered: (questionIdx: number, answerIdx: number) => {
    reactGA.event({
      category: 'Question',
      action: 'Answered',
      label: `Question ${questionIdx}`,
      value: answerIdx,
    });
  },
  surveyCompleted: () => {
    reactGA.event({ category: 'Survey', action: 'Completed' });
  },
  contactUsButtonClicked: () => {
    reactGA.event({ category: 'Contact Us', action: 'Clicked' });
  },
  emailSubmitted: (email: string) => {
    reactGA.event({ category: 'Email', action: 'Submitted', label: email });
  },
};

const ga = { initialize, events };
export default ga;
