import { v4 as uuidv4 } from 'uuid';

const generateRandomHash = (): string => {
  return uuidv4();
};

const getSessionId = (): string => {
  const storedSessionId = sessionStorage.getItem('sessionId');
  if (storedSessionId) {
    return storedSessionId;
  } else {
    const newSessionId = generateRandomHash();
    sessionStorage.setItem('sessionId', newSessionId);
    return newSessionId;
  }
};

export default getSessionId;
