import React, { FC } from 'react';

type TLayoutProps = { children: React.ReactNode };
const Layout: FC<TLayoutProps> = ({ children }) => {
  return (
    <div className="justify-center flex-center flex-column">
      <div className="justify-center flex-center flex-column content">
        <img
          src="logo.png"
          className="logo"
          alt="TechMiners Logo"
          width="200px"
        />
        {children}
      </div>
    </div>
  );
};

export default Layout;
