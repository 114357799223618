import getSessionId from './getSessionId';
import ga from './googleAnalytics';
import { Category, CategoryScore, Option } from '../models';
import { questions } from '../data/Data';
import axios from 'axios';

const storeResult = ({
  scoreBars,
  answers,
}: {
  scoreBars: Map<Category, CategoryScore>;
  answers: Option[];
}) => {
  const sessionId = getSessionId();
  ga.events.surveyCompleted();

  const url = process.env.REACT_APP_ZAPIER_QUESTIONNAIRE_WEBHOOK_URL;
  if (!url) return;

  const resultData = {
    sessionId,
    submittedAt: new Date(),
    answers: answers.map((option, index) => ({
      question: questions[index],
      answer: option,
    })),
    scoreBars: Array.from(scoreBars.entries()),
  };

  axios.post(url, resultData, {
    transformRequest: [(data, _headers) => JSON.stringify(data)],
  });
};

const storeEmail = (email: string) => {
  const sessionId = getSessionId();
  ga.events.emailSubmitted(email);

  const url = process.env.REACT_APP_ZAPIER_EMAIL_WEBHOOK_URL;
  if (!url) return;

  const submissionData = { email, sessionId, submittedAt: new Date() };
  axios.post(url, submissionData, {
    transformRequest: [(data, _headers) => JSON.stringify(data)],
  });
};

const zapier = { storeResult, storeEmail };
export default zapier;
