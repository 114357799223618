import { useState } from 'react';
import './theme/fonts/style.css';
import './App.css';
import ga from './utils/googleAnalytics';
import Layout from './components/Layout';
import StartPage from './components/StartPage';
import Questionnaire from './components/Questionnaire';

function App() {
  const [showQuestionnaire, setShowQuestionnaire] = useState<boolean>(false);

  const handleStart = () => {
    ga.events.startButtonClicked();
    setShowQuestionnaire(true);
  };

  const handleClickPrivacy = () => {
    const url = process.env.REACT_APP_PRIVACY_LINK;
    window.open(url, '_blank');
  };

  return (
    <Layout>
      {showQuestionnaire ? (
        <Questionnaire handleClickPrivacy={handleClickPrivacy} />
      ) : (
        <StartPage handleStart={handleStart} handleClickPrivacy={handleClickPrivacy} />
      )}
    </Layout>
  );
}

export default App;
